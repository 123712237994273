import React from 'react'

import Dashboard from '../components/Dashboard'
import Layout from '../components/layout/Layout'

export default function () {
	return (
		<Layout>
			<Dashboard />
		</Layout>
	)
}

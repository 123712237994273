import React, { useEffect, useState, useMemo } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { IdentityContextProvider, useIdentityContext } from 'react-netlify-identity'
import LocalizationProvider from '@mui/lab/LocalizationProvider'

import HomePage from './pages/HomePage'
import LoginPage from './pages/LoginPage'
import AuthPage from './pages/AuthPage'
import DateAdapter from '@mui/lab/AdapterLuxon'
import Container from '@mui/material/Container'
import KeysState from './context/keys/KeysState'
import { ENABLE_PUBLIC_LOGIN, SITE_URL } from './utils/constants'

export default function App() {
	return (
		<Container maxWidth="xl">
			<IdentityContextProvider url={SITE_URL}>
				<KeysState>
					<LocalizationProvider dateAdapter={DateAdapter}>
						<BrowserRouter>
							<Switch>
								<PublicRoute path="/login" component={LoginPage} />
								{ENABLE_PUBLIC_LOGIN && <PublicRoute path="/auth" exact={true} component={AuthPage} />}

								<PrivateRoute path="/" exact={true} component={HomePage} />
								<Route component={() => <Redirect to="/login" />} />
							</Switch>
						</BrowserRouter>
					</LocalizationProvider>
				</KeysState>
			</IdentityContextProvider>
		</Container>
	)
}

const PublicRoute = props => {
	const { isLoggedIn } = useIdentityContext()

	return isLoggedIn ? <Redirect to="/" /> : <Route {...props} />
}

const PrivateRoute = props => {
	const { isLoggedIn } = useIdentityContext()

	return isLoggedIn ? <Route {...props} /> : <Redirect to="/login" />
}

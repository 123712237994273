import { useEffect, useState, useContext } from 'react'
import { DateTime } from 'luxon'
import axios from 'axios'
import TextField from '@mui/material/TextField'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import LoadingButton from '@mui/lab/LoadingButton'
import SyncIcon from '@mui/icons-material/Sync'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Button, IconButton, Link } from '@mui/material'

import KeysContext from '../context/keys/KeysContext'
import { BASE_URL } from '../utils/constants'

export default function Dashboard() {
	const { apiKey, loadKeys } = useContext(KeysContext)

	const [loading, setLoading] = useState()
	const [selectedStartDate, setSelectedStartDate] = useState(DateTime.now().minus({ weeks: 1 }))
	const [selectedEndDate, setSelectedEndDate] = useState(DateTime.now())

	useEffect(() => {
		loadKeys()
	}, [])

	const updateGoogleSheet = async () => {
		try {
			console.log('googleSheetKey', apiKey)

			setLoading(true)
			const data = {
				start: selectedStartDate.toISODate(),
				end: selectedEndDate.toISODate(),
			}

			const options = {
				headers: {
					token: apiKey,
				},
			}

			const res = await axios.post(`${BASE_URL}/googlesheet`, data, options)

			const sheetId = res.data.id

			window.open(`https://docs.google.com/spreadsheets/d/1kZdsYwcIP47bgMAl_ezkaCnpZzHNXskCg6DESuHcGGI/edit#gid=${sheetId}`)
		} catch (error) {
			alert('Unexpected Error')
		} finally {
			setLoading(false)
		}
	}

	return (
		<div>
			<div className="filter-row">
				<DesktopDatePicker
					margin="normal"
					label="From"
					inputFormat="MM/dd/yyyy"
					value={selectedStartDate}
					onChange={setSelectedStartDate}
					renderInput={params => <TextField className="control min-width margin" {...params} />}
				/>

				<DesktopDatePicker
					margin="normal"
					label="To"
					inputFormat="MM/dd/yyyy"
					value={selectedEndDate}
					onChange={setSelectedEndDate}
					renderInput={params => <TextField className="control min-width margin" {...params} />}
				/>

				<LoadingButton
					variant="contained"
					className="control margin"
					onClick={updateGoogleSheet}
					loading={loading}
					loadingPosition="start"
					startIcon={<SyncIcon />}>
					Update Google Sheet
				</LoadingButton>

				<Button
					variant="outline"
					className="control margin"
					startIcon={<OpenInNewIcon />}
					component={Link}
					href="https://docs.google.com/spreadsheets/d/1kZdsYwcIP47bgMAl_ezkaCnpZzHNXskCg6DESuHcGGI"
					target="_blank">
					Open
				</Button>
			</div>
		</div>
	)
}

import { useReducer } from 'react'
import { useIdentityContext } from 'react-netlify-identity'
import KeysReducer from './KeysReducer'
import KeysContext from './KeysContext'
import { KEYS_SUCCESS } from '../types.js'
import axios from 'axios'

export default function KeysState(props) {
	const initialState = {
		apiKey: undefined,
	}

	const identity = useIdentityContext()

	const [state, dispatch] = useReducer(KeysReducer, initialState)

	const loadKeys = async () => {
		try {
			const key = sessionStorage.getItem('apiKey')
			if (key) return dispatch({ type: KEYS_SUCCESS, payload: JSON.parse(key) })

			const freshToken = await identity.getFreshJWT()

			const options = {
				headers: {
					Authorization: `Bearer ${freshToken}`,
				},
			}
			const response = await axios('/.netlify/functions/keys', options)

			dispatch({ type: KEYS_SUCCESS, payload: response.data })
		} catch (err) {
			console.error(err)
		}
	}

	return (
		<KeysContext.Provider
			value={{
				apiKey: state.apiKey,
				loadKeys,
			}}>
			{props.children}
		</KeysContext.Provider>
	)
}
